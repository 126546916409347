import * as tingle from "tingle.js";

/**
 * Open the national champions bio modal
 * @page /champions
 */
export function champions(element: HTMLElement) {
    // Grab all the champion data from the data attributes
    const { name, bio, photo, hospitalUrl, hospital } = element.dataset as { name: string; bio: string; photo: string; hospitalUrl: string; hospital: string };

    const championModal = new tingle.modal({
        cssClass: ["champion-modal", "tingle-full", "overflow"],
    });

    const markup = `
            <div class='row'>
                <div class='col-sm-6 champion-img'>
                    <a href='${hospitalUrl}' target='_blank'>
                        <img src='${photo}' alt='${name}'>
                    </a>
                </div>
                <div class='col-sm-6 champion-content'>
                    <h2>${name}</h2>
                    <h3 class="champion-hospital"><a href="${hospitalUrl}">${hospital}</a></h3>
                    <p class="champion-bio">${bio}</p>
                </div>
            </div>`;

    championModal.open();
    championModal.setContent(markup);
    return championModal;
}
